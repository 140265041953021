import React from 'react';
import UsersList from '../components/UsersList';
import Toasts from '../components/Toasts'; // Importamos el componente de Toasts



const Usuarios = () => {
  return (
    <>
      <div className="flex items-center justify-center h-full">
      <UsersList />
      </div>
      <Toasts /> {/* Usamos el componente Toasts aquí */}
    </>
  );
};

export default Usuarios;
