import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { ReactMediaRecorder } from 'react-media-recorder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faMicrophone, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';


function ChatComponent() {
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [message, setMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const fileInputRef = useRef(null);
  const chatEndRef = useRef(null);

  const API_URL = process.env.REACT_APP_API_URL;
  const CONTACTS_URL = process.env.REACT_APP_CONTACTS_URL;

  const fetchChatHistory = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_URL}/getChatHistory`);
      setChatMessages(data);
    } catch (error) {
      console.error('Error al recuperar el historial de chat:', error);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchChatHistory();
    const intervalId = setInterval(() => {
      fetchChatHistory();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [fetchChatHistory]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const { data } = await axios.get(CONTACTS_URL);
        const formattedContacts = data.map((contact) => ({
          value: contact.id,
          label: contact.nombre,
        }));
        setContacts([{ value: 'all', label: 'Seleccionar Todos' }, ...formattedContacts]);
      } catch (error) {
        toast.error('Error al cargar los contactos');
      }
    };

    fetchContacts();
    const intervalId = setInterval(() => {
      fetchContacts();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [CONTACTS_URL]);

  const handleChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === 'all')) {
      setSelectedContacts(contacts.filter(contact => contact.value !== 'all'));
    } else {
      setSelectedContacts(selectedOptions);
    }
  };

  const handleFileChange = async (e) => {
    if (selectedContacts.length === 0) {
      toast.warn('Debe seleccionar al menos un contacto.');
      return;
    }

    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    setIsSending(true);

    const fileType = selectedFile.type.startsWith('image/')
      ? 'photo'
      : selectedFile.type.startsWith('audio/')
      ? 'audio'
      : selectedFile.type.startsWith('video/')
      ? 'video'
      : 'document';

    const date = new Date().toLocaleString();

    const fileMessage = {
      type: fileType,
      content: selectedFile.name,
      sender: 'me',
      date: date,
    };

    setChatMessages((prevMessages) => [...prevMessages, fileMessage]);

    await sendFile(selectedFile, fileType);

    setIsSending(false);

    fetchChatHistory();
  };

  const sendFile = async (fileToSend, fileType) => {
    const formData = new FormData();
    formData.append('file', fileToSend);
    formData.append('chatIds', JSON.stringify(selectedContacts.map((user) => user.value)));
    formData.append('type', fileType);

    try {
      await axios.post(`${API_URL}/sendFile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Archivo enviado correctamente.');
    } catch (error) {
      toast.error('Error al enviar el archivo.');
    }
  };

  const handleSendMessage = async () => {
    if (selectedContacts.length === 0) {
      toast.warn('Debe seleccionar al menos un contacto.');
      return;
    }

    if (!message.trim()) {
      toast.warn('Debe escribir texto para enviar.');
      return;
    }

    setIsSending(true);

    const date = new Date().toLocaleString();

    const newMessage = {
      type: 'text',
      content: message,
      sender: 'me',
      date: date,
    };

    setChatMessages((prevMessages) => [...prevMessages, newMessage]);

    try {
      await axios.post(`${API_URL}/sendMessage`, {
        chatIds: selectedContacts.map((user) => user.value),
        text: message,
      });
      toast.success('Mensaje enviado correctamente.');
      setMessage('');

      fetchChatHistory();
    } catch (error) {
      toast.error('Error al enviar el mensaje.');
    } finally {
      setIsSending(false);
    }
  };

  const sendAudioMessage = async (audioFile, audioURL) => {
    if (selectedContacts.length === 0) {
      toast.warn('Debe seleccionar al menos un contacto.');
      return;
    }

    setIsSending(true);

    const date = new Date().toLocaleString();

    const newAudioMessage = {
      type: 'audio',
      content: audioURL,
      sender: 'me',
      date: date,
    };

    setChatMessages((prevMessages) => [...prevMessages, newAudioMessage]);

    await sendFile(audioFile, 'audio');

    setIsSending(false);

    fetchChatHistory();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages]);

  return (
    <div className="flex flex-col p-6 bg-white bg-opacity-00 backdrop-blur-md rounded-lg shadow-md w-full max-w-3xl overflow-hidden">
      <h1 className="text-2xl font-bold mb-4 text-center">Enviar Mensaje</h1>

      <div className="mb-4">
        <label className="block font-bold mb-2">Seleccionar contactos:</label>
        <Select
          isMulti
          options={contacts}
          value={selectedContacts}
          onChange={handleChange}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Buscar y seleccionar contactos"
        />
      </div>

      <div className="flex-grow mb-4 h-[350px] overflow-y-auto bg-gray-200 p-4 rounded">
        {chatMessages.map((msg, index) => (
          <div
            key={index}
            className={`mb-2 p-2 rounded ${
              msg.sender === 'me' ? 'bg-blue-200 text-right ml-auto' : 'bg-gray-300 text-left'
            }`}
            style={{ maxWidth: '60%', wordWrap: 'break-word' }}
          >
            {msg.type === 'text' && <p>{msg.content}</p>}
            {msg.type === 'photo' && (
              <div>
                <img 
                  src={`${API_URL}/files/${msg.content}`} 
                  alt="Imagen" 
                  style={{ maxWidth: '100%', borderRadius: '8px' }} 
                />
              </div>
            )}
            {msg.type === 'video' && (
              <div>
                <video controls style={{ maxWidth: '100%', borderRadius: '8px' }}>
                  <source src={`${API_URL}/files/${msg.content}`} type="video/mp4" />
                  Tu navegador no soporta el elemento de video.
                </video>
              </div>
            )}
            {msg.type === 'audio' && (
              <div>
                <audio controls src={`${API_URL}/files/${msg.content}`} style={{ width: '100%' }}></audio>
              </div>
            )}
            {msg.type === 'document' && (
              <div>
                <p>
                  <FontAwesomeIcon icon={faPaperclip} />{' '}
                  <a href={`${API_URL}/files/${msg.content}`} target="_blank" rel="noopener noreferrer">
                    {msg.content}
                  </a>
                </p>
              </div>
            )}
            <small className="text-gray-600 block text-right mt-1">{msg.date}</small>
          </div>
        ))}
        <div ref={chatEndRef} />
      </div>

      <div className="relative flex items-center bg-gray-200 rounded-full p-2">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Mensaje"
          className="flex-grow bg-transparent outline-none text-gray px-3"
          onKeyPress={handleKeyPress}
        />

        <button className="text-gray-500 px-2" onClick={() => fileInputRef.current.click()}>
          <FontAwesomeIcon icon={faPaperclip} size="lg" />
        </button>

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />

        <ReactMediaRecorder
          audio
          audioType="ogg"
          onStop={(blobUrl, blob) => {
            if (!blob || blob.size === 0) {
              console.error('Error al grabar el audio. Inténtalo de nuevo.');
              return;
            }
            const audioURL = URL.createObjectURL(blob);
            const audioFile = new File([blob], 'audioMessage.ogg', { type: 'audio/ogg' });
            sendAudioMessage(audioFile, audioURL);
          }}
          render={({ startRecording, stopRecording }) => (
            <button
              onClick={() => {
                if (isRecording) {
                  stopRecording();
                  setIsRecording(false);
                } else {
                  startRecording();
                  setIsRecording(true);
                }
              }}
              className={`p-2 rounded-full ml-2 ${isRecording ? 'bg-red-500' : 'bg-blue-500'} text-white transition-transform transform hover:scale-105`}
            >
              <FontAwesomeIcon icon={faMicrophone} />
            </button>
          )}
        />

        <button
          onClick={handleSendMessage}
          className="bg-blue-500 text-white p-2 rounded-full ml-2 transition-transform transform hover:scale-105"
          disabled={isSending}
        >
          {isSending ? <ClipLoader size={20} color={'#fff'} /> : <FontAwesomeIcon icon={faPaperPlane} />}
        </button>
      </div>
    </div>
  );
}

export default ChatComponent;
