import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Nav from './components/Nav'; // Componente de navegación
import Chat from './views/Chat'; // Vista de Chat
import Usuarios from './views/Usuarios'; // Vista de Usuarios
import Login from './views/Login'; // Vista de Login
import PageTransition from './components/PageTransition'; // Componente para la transición




function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Estado para manejar la sesión
  const location = useLocation(); // Obtener la ubicación actual para detectar cambios de ruta

  // Verificar si el usuario ya tiene una sesión activa
  useEffect(() => {
    const loggedIn = localStorage.getItem('loggedIn');
    setIsLoggedIn(!!loggedIn); // Si existe en localStorage, el usuario está logueado
  }, []);

  // Función para manejar el login
  const handleLogin = () => {
    localStorage.setItem('loggedIn', 'true'); // Guarda el estado en localStorage
    setIsLoggedIn(true);
  };

  // Función para manejar el logout
  const handleLogout = () => {
    localStorage.removeItem('loggedIn'); // Elimina el estado de sesión
    setIsLoggedIn(false);
  };

  return (
    <div className="flex flex-col h-screen">
      {/* Mostrar Nav solo si está logueado */}
      {isLoggedIn && <Nav onLogout={handleLogout} />}
      
      {/* Contenedor principal ajustado con Tailwind */}
      <div className={`flex-grow overflow-auto ${isLoggedIn ? 'pt-16' : ''}`}>
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route
              path="/"
              element={
                isLoggedIn ? (
                  <PageTransition>
                    <Chat onLogout={handleLogout} />
                  </PageTransition>
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/usuarios"
              element={
                isLoggedIn ? (
                  <PageTransition>
                    <Usuarios />
                  </PageTransition>
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/login"
              element={
                isLoggedIn ? (
                  <Navigate to="/" replace />
                ) : (
                  <PageTransition>
                    <Login onLogin={handleLogin} />
                  </PageTransition>
                )
              }
            />
          </Routes>
        </AnimatePresence>
      </div>
    </div>
  );
}

export default App;