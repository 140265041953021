import React from 'react';
import ChatComponent from '../components/ChatComponent';
import Toasts from '../components/Toasts'; // Importamos el componente de Toasts

const Chat = () => {
  return (
    <>
      <div className="flex items-center justify-center h-full">
        <ChatComponent />
      </div>
      <Toasts />
    </>
  );
};

export default Chat;




