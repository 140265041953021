import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';

const Nav = ({ onLogout }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleToggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <div>
      {/* Navegación estándar */}
      <nav className="w-full bg-white shadow-md p-4 fixed top-0 left-0 z-10">
        <div className="max-w-[1500px] mx-auto flex justify-between items-center">
          <Link to="/" >
          <img
            src="logo1000rifas.png"
            alt="Logo"
            className="h-11"
          />
          </Link>
          <ul className="hidden md:flex justify-center flex-grow space-x-6">
            <li>
              <Link to="/" className="text-gray-600 hover:text-blue-500 transition-colors duration-300">
                Inicio
              </Link>
            </li>
            <li>
              <Link to="/usuarios" className="text-gray-600 hover:text-blue-500 transition-colors duration-300">
                Usuarios
              </Link>
            </li>
          </ul>
          <div className="hidden md:block">
            <button
              onClick={onLogout}
              className="flex items-center text-gray-100 bg-red-500 px-4 py-2 rounded-lg transition duration-200 hover:bg-red-600">
              <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
              Salir
            </button>
          </div>
          {/* Botón hamburguesa para versión móvil */}
          <button
            className="md:hidden text-gray-600"
            onClick={handleToggleMobileMenu}>
            <FontAwesomeIcon icon={faBars} size="lg" />
          </button>
        </div>
      </nav>

      {/* Offcanvas para menú móvil */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            className="fixed top-0 left-0 w-1/2 h-full bg-white shadow-lg z-20"
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ type: 'tween', duration: 0.3 }}>
            <div className="p-4">
              <div className="flex justify-between items-center mb-4">
                <img src="logo1000rifas.png" alt="Logo" className="h-8" />
                <button onClick={closeMobileMenu} className="text-gray-600">
                  <FontAwesomeIcon icon={faTimes} size="lg" />
                </button>
              </div>
              <ul className="space-y-4">
                <li>
                  <Link
                    to="/"
                    onClick={closeMobileMenu}
                    className="text-gray-600 hover:text-blue-500 transition-colors duration-300 block">
                    Inicio
                  </Link>
                </li>
                <li>
                  <Link to="/usuarios" onClick={closeMobileMenu} className="text-gray-600 hover:text-blue-500 transition-colors duration-300 block">Usuarios</Link>
                </li>
                <li>
                  <button
                    onClick={() => {
                      onLogout();
                      closeMobileMenu();
                    }}
                    className="flex items-center text-gray-100 bg-red-500 px-4 py-2 rounded-lg transition duration-200 hover:bg-red-600 w-full"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                    Salir
                  </button>
                </li>
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Nav;
